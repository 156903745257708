$(() => {
  const $limitSelector = $('select[name="limit"]');

  $(window).on('load', () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const limit = urlParams.get('limit');
    const $optionSelector = $(`option[value="${limit}"`);
    if ($optionSelector !== undefined && limit !== null) {
      $limitSelector.val(limit).trigger('change');
    }
    if ($limitSelector.length) {
      $limitSelector.on('change', (event) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('limit', event.target.value);
        window.location.search = `?${searchParams.toString()}`;
      });
    }
  });
});
