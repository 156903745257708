/**
 * Run JS on document ready
 *
 */
$(document).ready(() => {
  // Trigger jquery events for current Rails controller and action
  const { controller, action } = $('body').data();
  $.event.trigger(`${controller}.load`);
  $.event.trigger(`${action}_${controller}.load`);

  if ($('#user_password_change_password').length || $('#user_password').length) {
    // Choosing a new password
    $('#user_password').strength({
      strengthClass: 'password required form-control strength',
    });
    $('#user_password_change_password').strength({
      strengthClass: 'password required form-control strength',
    });
  }

  // UI initialisers
  $("[data-bs-toggle='tooltip']").tooltip();
  $("[data-bs-toggle='popover']").popover();
  $('.js-hide').hide();
  $('.js-show').show();

  const fixedNavbar = $('.navbar-fixed-top');
  fixedNavbar.css('position', 'fixed');
  $('body').css('padding-top', fixedNavbar.outerHeight());

  // Split a single list of resources up with a separator
  // Not really easy with CSS or additional Haml view code
  $('ul li.recently-updated').last().addClass('list-seperator');

  $('.datepicker').on('input', (event) => {
    const submitButton = $('input[type=submit]', event.target.closest('form'));
    submitButton.prop('disabled', false);
  });
});
